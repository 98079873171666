import React, { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import CellWhite from '@/public/CellWhite.svg'
// import LaBambi from '@/public/LaBambi.png'
import styles from '@/styles/Navbar.module.css'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import LanguageSwitcher from './LanguageSwitcher'
const ScrollMenu = dynamic(() => import('@/components/ScrollMenu'))

const Navbar = () => {
  const [activeIdx, setActiveIdx] = useState(-1)
  const { t, i18n } = useTranslation()
  return (
    <>
      <header className={styles.navbarHeader}>
        <nav className={styles.nav}>
          <Link href={'/'}>
            <h2
              onClick={() => setActiveIdx(0)}
              className={styles.logo}
              onTouchStart={() => setActiveIdx(0)}
            >
              La Bambi
            </h2>

            {/* <Image
              className={styles.logoShadow}
              src={LaBambi}
              as='svg'
              alt={t('mobile bild click för att kontakta oss')}
              width={55}
              height={55}
              sizes='(max-width: 768px) 100vw,
                  (max-width: 1200px) 50vw,
                  33vw'
            /> */}
          </Link>
          <LanguageSwitcher />
          <a aria-label='Ring oss' href='tel:08934910' title='Ring oss'>
            <figure className={styles.phoneCall}>
              <Image
                src={CellWhite}
                as='svg'
                alt={t('mobile bild click för att kontakta oss')}
                width={36}
                height={35}
                sizes='(max-width: 768px) 100vw,
                  (max-width: 1200px) 50vw,
                  33vw'
              />
            </figure>
            <p>{t('Call Us')}</p>
            08-93 49 10
          </a>
        </nav>
      </header>
      <ScrollMenu />
    </>
  )
}

export default Navbar
