import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
const Moon = dynamic(() => import('../app/_icons/Moon'), { ssr: false })
const Sun = dynamic(() => import('../app/_icons/Sun'), { ssr: false })
import styles from '@/styles/DarMode.module.css'

const DarkMode = () => {
  const [darkMode, setDarkMode] = useState(() => {
    // Get the stored dark mode value from localStorage, defaulting to false if not found
    const storedDarkMode =
      typeof window !== 'undefined' ? localStorage.getItem('darkMode') : null
    return storedDarkMode === 'true'
  })

  const [isScrolling, setIsScrolling] = useState(false)

  // Apply dark mode class and set icon on initial render
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  useEffect(() => {
    let timeoutId

    const handleScroll = () => {
      setIsScrolling(true)
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setIsScrolling(false)
      }, 2000)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(timeoutId)
    }
  }, [])

  const switchMode = () => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)

    // Update localStorage to persist dark mode preference
    localStorage.setItem('darkMode', newDarkMode ? 'true' : 'false')
  }

  return (
    <button
      onClick={switchMode}
      className={`${styles.darkMode} ${isScrolling ? styles.hidden : ''}`}
      // Provide descriptive label for screen readers
    >
      {darkMode ? <Moon /> : <Sun />}
    </button>
  )
}

export default DarkMode
