import '@/styles/globals.css'
// import dynamic from 'next/dynamic'
import { Roboto } from 'next/font/google'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
// import { useEffect } from 'react'
// import { useRouter } from 'next/router'
// Import translations
import svTranslations from './locales/sv/translation.json' //
import { appWithTranslation } from 'next-i18next'

const roboto = Roboto({
  weight: ['100', '400', '700', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
})
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import DarkMode from '@/components/DarkMode'
//NOTE  Dynamic Imports: Dynamic imports are used for Navbar, Footer, and DarkMode components, which is a good practice for code-splitting. However, consider whether dynamic imports are necessary for these components. If they're essential for every page, you might want to import them directly in _app.js to ensure they're loaded with the initial page load.
// const Navbar = dynamic(() => import('@/components/Navbar'))
// const Footer = dynamic(() => import('@/components/Footer'))
// const DarkMode = dynamic(() => import('@/components/DarkMode'))



// Initialize i18next instance
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'sv', // Set default language to Swedish
  resources: {
    sv: {
      translation: svTranslations, // Load Swedish translations
    },
    // Add more languages and their translations if needed
  },
})
function MyApp({ Component, pageProps }) {
  return (
    <main className={roboto.className}>
      <I18nextProvider i18n={i18next}>
        <Navbar />
        <DarkMode />
        <Component {...pageProps} />
        <Footer />
      </I18nextProvider>
    </main>
  )
}
export default appWithTranslation(MyApp)
