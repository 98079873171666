import styles from '@/styles/Footer.module.css'
import dynamic from 'next/dynamic'
import { useTranslation } from 'react-i18next'

const Map = dynamic(() => import('@/app/map/Map'))
const Extras = dynamic(() => import('@/components/Extras'))

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  const { t, i18n } = useTranslation()

  return (
    <footer className={styles.main}>
      <h2 className={styles.cardTitle}>{t('Find Our Restaurant')}</h2>
      <main className={styles.grid}>
        <section className={styles.cardOne}>
          <div className={styles.map}>
            <Map address='Sparres väg 1, 197 33 Bro' />
          </div>
        </section>
        <Extras />
      </main>
      <h3 className={styles.copyRight}>Copyright &copy; {year}</h3>
    </footer>
  )
}
export default Footer
