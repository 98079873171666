import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '@/styles/LanguageSwitcher.module.css'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
    if (typeof window !== 'undefined') {
      localStorage.setItem('selectedLanguage', selectedLanguage)
    }
  }, [i18n, selectedLanguage])

  const toggleLanguage = (lang) => {
    setSelectedLanguage(lang)
  }

  return (
    <div className={styles.btnWrapper}>
      <button
        onClick={() => toggleLanguage('en')}
        className={`${selectedLanguage === 'en' ? styles.active : ''} ${
          styles.btnEng
        }`}
      >
        EN
      </button>
      <button
        onClick={() => toggleLanguage('sv')}
        className={`${selectedLanguage === 'sv' ? styles.active : ''} ${
          styles.btnSv
        }`}
      >
        SV
      </button>
    </div>
  )
}

export default LanguageSwitcher
